<template>

	<tr
		:id="'player-' + player.id + '-advanced'"
		:class="{
			'player-advanced'	: true,
			'open'	: advanced_open
		}"
	>

		<td colspan="20"><div>
		
			<div class="last_5 games_historical block">

				<div class="block-head">5 derniers</div>

				<div class="scores-list_head scores-list display-flex"></div>
				
				<div class="display-flex scores-list">

					<a :href="game_url( game.game_url )" target="_blank" v-for="( game, key ) in player.ttfl.last_5.games" v-bind:key="key" class="score">
						<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
						<span :title="game.date" class="ttfl-score">{{ game.ttfl_score }}</span>
					</a>

				</div>

			</div>

			<div class="heat_map games_historical block">

				<div class="block-head">Heat map (30J)</div>

				<div class="scores-list_head scores-list display-flex">
					<div>&lt; 20</div>
					<div>&lt; 30</div>
					<div>&lt; 40</div>
					<div>&lt; 50</div>
					<div>&gt; 50</div>
				</div>
				
				<div class="scores-list display-flex">

					<div v-for="( score, key ) in player.ttfl.heat_map" v-bind:key="key" class="score">
						<span :style="'opacity:' + score.opacity" :class="'ttfl-score_bg ' + score.class"></span>
						<span class="ttfl-score">
							{{ score.float_value }}
						</span>
					</div>

				</div>

			</div>

			<div class="b2b games_historical block" v-if="player.ttfl.b2b_games.games.length > 0">

				<div class="block-head">B2B</div>

				<div class="scores-list_head scores-list display-flex"></div>
				
				<div class="display-flex scores-list">

					<a :href="game_url( game.game_url )" target="_blank" v-for="( game, key ) in player.ttfl.b2b_games.games" v-bind:key="key" class="score">
						<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
						<span :title="game.date" class="ttfl-score">{{ game.ttfl_score }}</span>
					</a>

				</div>

			</div>

			<div class="game_historical block" v-if="user_picks.length > 0">

				<div class="block-head">{{ user_pseudo }}</div>

				<div class="scores-list display-flex">

					<a
						v-for="( game, key ) in user_picks_filtered"
						v-bind:key="key"
						:href="game_url( game.game_url )"
						target="_blank"
						class="score"
					>
						<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
						<span class="ttfl-score">
							{{ game.ttfl_score }}
						</span>
					</a>

				</div>

			</div>

			<div class="mate_compare games_historical block">

				<div class="block-head">

					<button @click="change_without" :class="{ without : compare.without }">
						{{ compare.without == true ? 'SANS' : 'AVEC' }}
					</button>

					<vselect
						ref="user_select"
						id="user-select"
						:options="get_mates_options()"
						:select="player.player_id"
						:onchange="change_mate"
						:placeholder="'Coéquipier'"
					/>

					<!-- <select @change="change_mate">

						<option value="">Coéquipier</option>

						<option
							v-for="( player, key ) in get_mates()"
							v-bind:key="key"
							:value="player.player_id"
						>
							{{ player.fname.substring( 0, 1 ) + '. ' + player.lname }}
						</option>

					</select> -->

				</div>

				<div class="scores-list display-flex">

					<a
						v-for="( game, key ) in compare.games"
						v-bind:key="key"
						:href="game_url( game.game_url )"
						target="_blank"
						class="score"
					>
						<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
						<span class="ttfl-score">
							{{ game.ttfl_score }}
						</span>
					</a>

				</div>

			</div>

			<div class="upcoming" v-if="player.upcoming_games">

				<div class="display-flex">

					<div class="block-title">À VENIR</div>

					<div class="games block display-flex">

						<div
							v-for="( game, key ) in player.upcoming_games"
							v-bind:key="key"
							class="game games_historical block"
						>

							<div class="block-head">

								<span class="date">{{ game.date }}</span>
								<span v-if="game.home_game">VS</span>
								<span v-else>@</span>
								<img class="team-logo" v-if="game.team_opp && game.team_opp.logo != ''" :src="require( '@/assets/img/logos/' + game.team_opp.logo )">

							</div>

							<div class="scores-list_head scores-list display-flex">

								<div>3 DERNIERS VS</div>

								<div></div>
								<div></div>
								<div></div>
								
								<div>VS</div>
								<div>DEF</div>
								<div>H/A</div>
								<div>EST</div>

							</div>

							<div class="display-flex scores-list">

								<div
									v-for="index in 3"
									:key="index"
									class="score"
								>
									<span
										v-if="game.last_3vs.games[index - 1]"
										:style="'opacity:' + game.last_3vs.games[index - 1].ttfl_score.opacity"
										:class="'ttfl-score_bg ' + game.last_3vs.games[index - 1].ttfl_score.class"
									/>
									<span v-if="game.last_3vs.games[index - 1]" class="ttfl-score">
										{{ game.last_3vs.games[index - 1].ttfl_score.value }}
									</span>
								</div>

								<div class="empty score"></div>

								<div class="score">

									<span :style="'opacity:' + game.index_vs.opacity" :class="'ttfl-score_bg ' + game.index_vs.class"></span>
									<span class="ttfl-score">
										{{ game.index_vs.value }}
									</span>

								</div>

								<div class="score">

									<span :style="'opacity:' + game.index_def.opacity" :class="'ttfl-score_bg ' + game.index_def.class"></span>
									<span class="ttfl-score">
										{{ game.index_def.value }}
									</span>

								</div>

								<div class="score">

									<span :style="'opacity:' + game.index_homeaway.opacity" :class="'ttfl-score_bg ' + game.index_homeaway.class"></span>
									<span class="ttfl-score">
										{{ game.index_homeaway.value }}
									</span>

								</div>

								<div class="score">

									<span :style="'opacity:' + game.estimation.opacity" :class="'ttfl-score_bg ' + game.estimation.class"></span>
									<span class="ttfl-score">
										{{ game.estimation.value }}
									</span>

								</div>

							</div>
							
						</div>

					</div>

				</div>

			</div>

		</div></td>

	</tr>

</template>

<script>

	import mixin_tools from '@/mixins/tools'
	import mixin_player from '@/mixins/player'

	export default {
		
		name	: 'playerRowAdvanced',

		components: {

			vselect	: () => import( '@/components/form/vselect' ),

		},

		mixins	: [ mixin_tools, mixin_player ],

		props	: [ 'player' ],

		data	: function() { return {
		}},

		methods	: {
		},

		watch	: {
		},

		computed	: {

			user_picks_filtered() {

				var _this	= this

				return _this.user_picks.filter(function( pick ) {

					// return _this.$moment( pick.date ).format( 'YYYY-MM-DD' ) != _this.$parent.$parent.$parent.day.format( 'YYYY-MM-DD' )	// On retire juste le pick du jour affiché
					return _this.$moment( pick.date ).format( 'YYYY-MM-DD' ) != _this.$moment().format( 'YYYY-MM-DD' )	// On retire juste le pick du jour actuel
				})

			},

		},

		mounted	: function() {
		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';



	/** ---------------------------------------*\
	*---------------------------------------- **/

	.mate_compare .block-head {
		
		button, .vselect {
			height: 40px;
		}

		button {
			
			border-right: 0;

			&.without {
				background-color: #000;
				color: #FFF;
			}

		}

		// .vselect .vselect-options {
		// 	width: 300px;
		// }

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.mate_compare .block-head .vselect::v-deep .vselect-options {
		width: 225px;
	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#players tr.player-advanced {

		& > td {
			display: none;
		}

		& > td > div {

			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: stretch;
			padding: 15px;

		}

		&.open {

			& > td {
				display: revert;
			}

		}

		.block {
			margin-right: 15px;
		}

		.block .block-head,
		.block .display-flex {
			width: 100%;
		}

		.block .block-head {

			display: flex;
			align-items: center;
			padding: 5px 10px;
			margin: 0;
			border: 2px solid #000;
			border-bottom: 0;
			font-weight: bold;
			font-size: 0.9em;
			text-transform: uppercase;

		}

		.block > .display-flex {

			margin-top: -1px;
			border: 2px solid #000;
		
		}

		.block div.display-flex .score {

			flex: auto;
			height: 35px;
			line-height: 35px;
			margin: 0;
			font-size: 0.85em;
			border-left: 1px solid #000;
			border-right: 1px solid #000;

			&:first-child {
				border-left: 0;
			}

			&:last-child {
				border-right: 0;
			}

		}

		.block div.display-flex.scores-list_head {

			width: 100%;
			height: 27px;
			border-bottom: 0;
			background-color: rgba( 0, 0, 0, 0.1 );

			& > div {

				display: flex;
				justify-content: center;
				align-items: center;
				flex: auto;
				height: 25px;
				text-transform: uppercase;
				font-size: 0.6em;
				line-height: 1em;
				font-weight: bold;
				color: #000;

			}

		}

		.last_5.games_historical > div.scores-list {
			width: 225px;
		}

		.heat_map.games_historical {

			.display-flex {
				justify-content: space-between;
			}

			span.ttfl-score_bg {
				background-color: $orange;
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#players tr.player-advanced .upcoming {
	
		width: 100%;
		margin-top: 25px;

		.display-flex {

			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-content: center;

			.block-title {

				flex: 100%;
				max-width: 100%;
				margin-bottom: 15px;
				font-size: 1.2em;
				font-weight: bold;

			}
			
		}

		.games {

			flex-flow: row wrap;
			flex: 100%;
			max-width: 100%;

		}

		.game {

			margin-right: 25px;
			margin-bottom: 25px;
			align-items: stretch;

			.block-head {

				justify-content: flex-start;
				padding: 0;

				span {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 35px;
					padding: 0 10px;
					margin: 0;
					margin-right: 10px;
					font-size: 0.875em;
					line-height: 1em;
				}

				.date {
					font-size: 0.85em;
					line-height: 1em;
					border-left: 1px solid #000;
					border-right: 1px solid #000;
				}

				.team-logo {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}

			}

			.date,
			.team_vs span {
				height: 100%;
				padding: 0 10px;
				text-transform: uppercase;
				white-space: nowrap;
				font-weight: bold;
			}

			.date {
				background: #000;
				color: #FFF;
			}

			.team_vs {

				display: flex;
				justify-content: flex-start;
				align-items: center;

				span {
					height: 100%;
					padding: 0 10px;
					// background: #000;
					// color: #FFF;
					font-weight: bold;
				}
				
				.team-logo {
					width: 25px;
					height: 25px;
					margin: 0 10px 0 0;
				}

			}

			.score {

				width: 42px;

				&.empty {
					background: #000;
				}
				
			}

		}

	}

</style>